const DriverDetail = (props) => {
	const data = props.data
	const appData = props.appData
	const update = () => {
		const requiredInputs = ['firstName', 'lastName', 'registrationNumber', 'DOB', 'department', 'joinDate', 'position']
		let body = {}
		let save = true
		for(let inputName of requiredInputs) {
			let temp = document.getElementById(inputName)
			if(temp && temp.value === '') {
				const borderColor = temp.style.borderColor
				temp.style.borderBottom = '1px solid red'
				temp.addEventListener('focus', () => {
					temp.style.borderColor = borderColor
				})
				save = false
			} else {
				body[inputName] = temp && temp.value ? temp.value : ''
				if((temp && temp.value) && inputName === 'position' || inputName === 'department') {
					body[inputName] = parseInt(body[inputName])
				}
			}
		}
		if(!save) {
			return
		}
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json")
		body['_id'] = data['_id']
		const raw = JSON.stringify(body)
		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}
		fetch("https://api.itsystem.mn/employee/update", requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if(result.status === 200) {
				props.fetchData()
				props.setDriver(null)
			} else {
				alert('Алдаа гарлаа')
			}
		})
		.catch((error) => console.error(error))
	}
	return (
		<div className="modalOverlay">
			<div className="modal">
				<h1 className="marginbottom1rem">Ажилтны мэдээлэл</h1>
				<div className="marginbottom1rem">
					<span className="button new" onClick={() => update()}>
						<img src="/img/save.svg" alt="" />
						<span className="button-title">Хадгалах</span>
					</span>
				</div>
				<div className="displayflex">
					<div className="width240px">
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Овог</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="firstName" type="text" className="width100percent" defaultValue={data.firstName} /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Нэр</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="lastName" type="text" className="width100percent" defaultValue={data.lastName} /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Зураг</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent">
								<span className="employeeImageHolder">
									<label>Upload image</label>
								</span>
							</span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Төрсөн огноо</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="DOB" type="date" className="width100percent" defaultValue={data.DOB}  /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Ажилд орсон огноо</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="joinDate" type="date" className="width100percent" defaultValue={data.joinDate} /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Регистер</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="registrationNumber" type="text" className="width100percent" defaultValue={data.registrationNumber} /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Харьяалагдах алба/хэлтэс</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent">
								<select className="formSelect width100percent" id="department" defaultValue={data.department}>
									{
										appData['departments'].map(department => {
											return <option value={department.departmentId}>{department.name}</option>
										})
									}
								</select>
							</span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Ажлын байр</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent">
								<select className="formSelect width100percent" id="position" defaultValue={data.position}>
									{
										appData['employees_positions'].map(position => {
											return <option value={position.positionId}>{position.positionTitle}</option>
										})
									}
								</select>
							</span>
						</div>
					</div>
				</div>
				<div className="close" onClick={() => props.setDriver(null)}><img src="/img/close.svg" alt="" /></div>
			</div>
			<div className="modalOverlayBackground"></div>
		</div>
	)
}

export default DriverDetail