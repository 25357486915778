import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react';
import { GoogleMapsContext, useMapsLibrary } from '@vis.gl/react-google-maps';

// Option 1: Assume Google Maps is loaded globally
// Make sure to load Google Maps script in your HTML or webpack config
function usePolyline(props) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    encodedPath,
    ...polylineOptions
  } = props;

  // This is here to avoid triggering the useEffect below when the callbacks change
  const callbacks = useRef({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut
  });

  const geometryLibrary = useMapsLibrary('geometry');
  const polyline = useRef(window.google.maps.Polyline ? new window.google.maps.Polyline() : null).current;

  // Update PolylineOptions 
  useMemo(() => {
    if (polyline) {
      polyline.setOptions(polylineOptions);
    }
  }, [polyline, polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // Update the path with the encodedPath
  useMemo(() => {
    if (!encodedPath || !geometryLibrary || !polyline) return;
    const path = geometryLibrary.encoding.decodePath(encodedPath);
    polyline.setPath(path);
  }, [polyline, encodedPath, geometryLibrary]);

  // Create polyline instance and add to the map once the map is available
  useEffect(() => {
    if (!map) {
      if (map === undefined)
        console.error('<Polyline> has to be inside a Map component.');
      return;
    }
    if (polyline) {
      polyline.setMap(map);
      return () => {
        polyline.setMap(null);
      };
    }
  }, [map, polyline]);

  // Attach and re-attach event-handlers when any of the properties change
  useEffect(() => {
    if (!polyline) return;
    
    // Add event listeners
    const gme = window.google.maps.event;
    [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut']
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polyline, eventName, (e) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    return () => {
      gme.clearInstanceListeners(polyline);
    };
  }, [polyline]);

  return polyline;
}

/**
 * Component to render a polyline on a map
 */
export const Polyline = forwardRef((props, ref) => {
  const polyline = usePolyline(props);
  useImperativeHandle(ref, () => polyline, []);
  return null;
});