import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

const DeviceHealth = () => {
    ChartJS.register(ArcElement, Tooltip, Legend)
    const data = {
        labels: ['Хэвийн', 'Анхаарах шаардлагатай', 'Ажиллахгүй байгаа'],
        datasets: [
        {
            label: 'Төхөөрөмжийн статус',
            data: [5, 1, 0],
            backgroundColor: [
                '#00cf00',
                'orange',
                'red'
                ],
            borderColor: [
                '#00cf00',
                'orange',
                'red'
                ],
            borderWidth: 1,
        },
        ]
    }
    const options = {
        plugins: {
            legend: {
                position: 'right',  // Position the legend to the right
                align: 'center',    // Center the legend horizontally
                labels: {
                    padding: 20,     // Add some vertical space between labels
                    boxWidth: 12,     // Size of the box next to the label
                    boxHeight: 12,    // Size of the box next to the label
                },
            },
        },
    }
    return <Doughnut data={data} options={options} />
}

export default DeviceHealth