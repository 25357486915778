import {useState, useEffect} from 'react'
import List from './List'
import Geofences from './Geofences'
import Polygon from './Polygon'

const Index = () => {
    const [data, setData] = useState(null)
    const fetchData = () => {
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/geofences/get?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	console.log(result)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData()
    }, [])
    return data ? (
        <>
            <List data={data} />
            <Geofences />
        </>
    ) : <div>Түр хүлээнэ үү...</div>
}

export default Index