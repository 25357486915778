const Filter = (props) => {
	console.log(props)
	let filterValues = props.data.map(val => {
		return val[props.filteringField]
	})
	const foo = () => {
		const values = document.getElementById('filter').querySelectorAll('input[type="checkbox"]')
		let temp = []
		for(let i in values) {
			if(values[i].checked) {
				temp.push(values[i].value)
			}
		}
		props.setFilters({
			...props.filters,
			[props.filteringField]: temp
		})
	}
	return (
		<div id="filter" style={{top: (parseInt(props.position.y) + 0) + 'px', left: props.position.x + 'px'}}>
			<h2>Фильтр</h2>
			{
				filterValues.map(val => {
					return (
						<div className="displayflex flexalignitemscenter">
							<div><input type="checkbox" value={val} /></div>
							<div className="marginlefthalfrem">{val}</div>
						</div>
					)
				})
			}
			<div>
				<button onClick={() => foo()}>Шүүх</button>
				<button onClick={() => props.setFilter(false)}>Цуцлах</button>
			</div>
		</div>
	)
}

export default Filter