import {useState} from 'react'
import Signin from './Signin'
import Recovery from './Recovery'

const Login = () => {
	const [signing, setSigning] = useState(false)
	const [recovery, setRecovery] = useState(false)
	const [email, setEmail] = useState('')
	const signin = () => {
		console.log('signing')
	}
	return (
		<div id="signin">
			<div id="bigImage"></div>
			<div id="containerSignin">
				<div>
					<img src="https://gps.itsystem.mn/itsys-logo.png" style={{width: '100px', margin: '0 0 2rem 0'}} alt="" />
					{recovery ? <Recovery setRecovery={setRecovery} email={email} setEmail={setEmail} /> : <Signin setRecovery={setRecovery} setEmail={setEmail} email={email} />}
					<div style={{margin: '2rem 0 0 0'}}>
						<img src="https://gps.itsystem.mn/img/appstore.jpg" alt="" />
						<img src="https://gps.itsystem.mn/img/playstore.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login