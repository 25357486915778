import {useState, useEffect} from 'react'
import {APIProvider, Map} from '@vis.gl/react-google-maps'
import {Polyline} from './Polyline'

const TracksVehicle = (props) => {
	const [data, setData] = useState(null)
	const [coordinates, setCoordinates] = useState(null)
	const fetchData = () => {
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/coordinates/list?vehicleId=${props.vehicleId}&token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	let coordinateData = []
        	result.map(c => {
        		coordinateData.push({lat: c.coordinates[0], lng: c.coordinates[1]})
        	})
        	if(coordinateData.length === 0) {
        		alert('Сонгосон хугацаанд мэдээлэл байхгүй байна.')
        	}
        	setCoordinates(coordinateData)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData()
    }, [])
	return data && coordinates ? (
		<APIProvider apiKey={"AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4"}>
    		<Map style={{width: '100vw', height: '100vh'}} defaultCenter={{lat: 43.056300, lng: 106.431007}} mapId={"e7dd2ce7fbfb92c2"} defaultZoom={8} gestureHandling={'greedy'} disableDefaultUI={true} >
    			<Polyline path={coordinates} />
    		</Map>
    	</APIProvider>
	) : <div>Түр хүлээнэ үү...</div>
}

export default TracksVehicle