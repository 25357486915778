import {useState, useEffect, memo} from 'react'
import List from './List'

const Entities = () => {
	const [data, setData] = useState(null)
	const fetchData = () => {
		const myHeaders = new Headers()
		myHeaders.append("Content-Type", "application/json")
		const requestOptions = {
			method: "GET",
			headers: myHeaders
		}
		fetch(`/entities/get?token=${localStorage.getItem('token')}`, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			setData(() => result)
		})
		.catch((error) => console.error(error))
	}
	useEffect(() => {
		fetchData()
	}, [])
	const refresh = () => {
		setData(null)
		fetchData(data)
	}
	return data ? (
		<div className="submodule" data="Харилцагч">
			<List data={data} refresh={refresh} />
		</div>
	) : <div className="padding1rem"><div className="loader"></div></div>
}

export default memo(Entities)