const NewEntity = (props) => {
	const save = (createNewAfterSave = false) => {
		const requiredInputs = ['entityCode', 'entityName', 'entityRegistrationNumber', 'entityTin']
		let body = {}
		let save = true
		for(let inputName of requiredInputs) {
			let temp = document.getElementById(inputName)
			if(temp && temp.value === '') {
				const borderColor = temp.style.borderColor
				temp.style.borderBottom = '1px solid red'
				temp.addEventListener('focus', () => {
					temp.style.borderColor = borderColor
				})
				save = false
			} else {
				body[inputName] = temp && temp.value ? temp.value : ''
			}
		}
		if(!save) {
			return
		}
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		const raw = JSON.stringify(body)
		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}
		fetch("/entities/insert", requestOptions)
		.then((response) => response.json())
		.then((result) => {
			console.log(result)
			if(result.status === 200) {
				if(createNewAfterSave) {

				} else {

				}
			}
		})
		.catch((error) => console.error(error))
	}
	return (
		<div className="modalOverlay">
			<div className="modal">
				<h1 className="marginbottom1rem">Шинэ харилцагч</h1>
				<div className="marginbottom1rem">
					<span className="button new" onClick={() => save()}>
						<img src="/img/save.svg" alt="" />
						<span className="button-title">Хадгалах</span>
					</span>
					<span className="button">
						<img src="/img/duplicate.svg" alt="" />
						<span className="button-title" onClick={() => save(true)}>Хадгалах + Шинэ</span>
					</span>
				</div>
				<div className="displayflex">
					<div className="width240px">
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Код</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="entityCode" type="text" className="width100percent" /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Нэр</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="entityName" type="text" className="width100percent" /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Нэр (англи)</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="entityNameEnglish" type="text" className="width100percent" /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Бизнесийн нэр</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="entityBusinessName" type="text" className="width100percent" /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>Регистер</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="entityRegistrationNumber" type="text" className="width100percent" /></span>
						</div>
						<div className="displayflex marginbottom1rem flexalignitemscenter">
							<label className="width40percent">
								<span>ТТД</span>
								<span className="required">*</span>
							</label>
							<span className="width60percent"><input id="entityTIN" type="text" className="width100percent" /></span>
						</div>
					</div>
				</div>
				<div className="close" onClick={() => props.setNewEntityForm(false)}><img src="/img/close.svg" alt="" /></div>
			</div>
			<div className="modalOverlayBackground"></div>
		</div>
	)
}

export default NewEntity

/*
Код
Нэр
Нэр (англи)
Бизнес нэр
Регистер
Татвар төлөгчийн дугаар
Бүлэг

Төрөл Байгууллага/Хувь хүн
Утас
Имэйл
Веб
Банк
Банкны данс
Хаяг

НӨАТ төлөгч
НХАТ төлөгч

Идэвхи
*/