const List = (props) => {
	return (
		<div className="leftPanel">
			<div className="paddinghalfrem">
				<input type="text" placeholder="Хайх" />
				<button>Шинэ</button>
			</div>
			{
				props.data.map(geofence => {
					return (
						<div className="bordertop1pxsolide3e4e6 paddinghalfrem">
							<input type="checkbox" checked />
							{geofence.name}
						</div>
					)
				})
			}
		</div>
	)
}

export default List