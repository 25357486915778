import {APIProvider, Map} from '@vis.gl/react-google-maps'
import {useState, useEffect} from 'react'

const Index = () => {
	const [data, setData] = useState(null)
	const [vehicle, setVehicle] = useState(null)
	const [dataInterval, setDataInterval] = useState(null)
    const foo = (i) => {
    	setDataInterval(i)
    }
    const fetchReport = () => {
    	console.log('fn:fetchReport')
    }
    const fetchData = () => {
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/vehicles/list?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	console.log(result)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData()
    }, [])
	return data ? (
		<>
			<div className="leftPanel">
				<div className="padding1rem">
					<div>
						Эхлэх огноо: <input type="date" className="floatright" id="tracksStartDate" />
					</div>
					<div className="margintophalfrem">
						Дуусах огноо: <input type="date" className="floatright" id="tracksEndDate" />
					</div>
					<div className="margintophalfrem">
						<select className="bigselect" id="" onChange={e => setVehicle(e.target.value)}>
							<option value="">-- тайлангийн төрөл --</option>
							<option value="group">Бүлгийн дэлгэрэнгүй тайлан</option>
							<option value="temperatur">Температурын тайлан</option>
							<option value="vehicle">Тээврийн хэрэгслийн дэлгэрэнгүй тайлан</option>
							<option value="fuel">Түлшний тайлан</option>
						</select>
					</div>
				</div>
			</div>
			<div className="rightTopPanel">
				<APIProvider apiKey={"AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4"}>
		    		<Map style={{width: '100vw', height: '100vh'}} defaultCenter={{lat: 43.056300, lng: 106.431007}} mapId={"e7dd2ce7fbfb92c2"} defaultZoom={8} gestureHandling={'greedy'} disableDefaultUI={true} >
		    		</Map>
		    	</APIProvider>
			</div>
			<div className="rightBottomPanel">

			</div>
		</>
	) : <div>Түр хүлээнэ үү...</div>
}

export default Index

/*
<select className="bigselect" id="tracksVehicle" onChange={e => setVehicle(e.target.value)}>
						<option value="">Машин сонгоно уу...</option>
						{
							data.map(vehicle => {
								return <option value={vehicle.vehicleId}>{vehicle.name}</option>
							})
						}
					</select>
					<select className="bigselect margintophalfrem" defaultValue={dataInterval} onChange={(e) => foo(e.target.value)} id="tracksDataInterval">
						<option value="" style={{color: '#ddd'}}>Хугацаа сонгоно уу...</option>
						<option value="today">Өнөөдөр</option>
						<option value="yesterday">Өчигдөр</option>
						<option value="thisweek">Энэ долоо хоног</option>
						<option value="thismonth">Энэ сар</option>
						<option value="interval">Огноо сонгох</option>
					</select>
					<div className="margintophalfrem">
						Эхлэх огноо: <input type="date" className="floatright" id="tracksStartDate" />
					</div>
					<div className="margintophalfrem">
						Дуусах огноо: <input type="date" className="floatright" id="tracksEndDate" />
					</div>
					<button className="bigbutton margintophalfrem" onClick={() => fetchReport()}>Шүүж харах</button>
					<div>
						<p>Замын тайлан</p>
						<p>Товч тайлан</p>
						<p>Сүүлийн мэдээ</p>
						<p>Хөдөлгүүрийн ажилласан цаг</p>
						<p>Хураангуй</p>
					</div>
*/