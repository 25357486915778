const Entry = (props) => {
	const data = props.data
	return (
		<div className="row data" onClick={() => props.setDriver(data)}>
			<div className="width30px displayflex flexalignitemscenter flexjustifycontentcenter">
				<input type="checkbox" className="check" />
			</div>
			<div className="width120px"><span>{data.firstName}</span></div>
			<div className="width120px"><span>{data.lastName}</span></div>
			<div className="width120px"><span></span></div>
			<div className="width120px"><span></span></div>
			<div className="width120px"><span></span></div>
		</div>
	)
}

export default Entry