import {useState} from 'react'

const Recovery = (props) => {
	const [requesting, setRequesting] = useState(false)
	const [recovered, setRecovered] = useState(false)
	function validateEmail(email) {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return emailPattern.test(email)
	}
	const recover = () => {
		const emailInput = document.getElementById('recoveringEmail')
		if(emailInput.value === '' || !validateEmail(emailInput.value)) {
			const inputPreviousColor = emailInput.style.borderColor
			emailInput.focus()
			emailInput.style.borderColor = 'red'
			setTimeout(() => {
				emailInput.style.borderColor = inputPreviousColor
			}, 1000)
			return
		}
		setRequesting(true)
		setTimeout(() => {
			setRecovered(true)
		}, 3000)
	}
	if(recovered) {
		return (
			<>
				<h2 className="margintop1rem marginbottom1rem">Бүртгэлтэй имэйл руу шинэ нууц үгийг илгээлээ.</h2>
				<p onClick={() => props.setRecovery(false)}>Нэвтрэх хэсэг руу очих</p>
			</>
		)
	}
	return requesting ? (<div>Түр хүлээнэ үү...</div>) : (
		<>
			<h1>Нууц үг сэргээх</h1>
			<h2 className="margintop1rem">Бүртгэлтэй имэйл хаягаа оруулаад "Нууц үг сэргээх" дарна уу.</h2>
			<input type="text" id="recoveringEmail" placeholder="Имэйл хаяг" className="margintop1rem" defaultValue={props.email} onKeyUp={(e) => props.setEmail(e.target.value)} />
			<button className="margintop1rem" onClick={() => recover()}>Нууц үг сэргээх</button>
		</>
	)
}

export default Recovery