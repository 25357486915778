import {useState, useEffect} from 'react'
import Detail from './Detail'

const MonitoringVehicles = (props) => {
	const [detail, setDetail] = useState(null)
	const [vehicle, setVehicle] = useState(null)
	useEffect(() => {
	    props.data.map(vehicle => {
	    	if(vehicle.allCoordinates.length > 0) {
	    		const coordinates = vehicle.allCoordinates[vehicle.allCoordinates.length - 1]['coordinates']
	    		console.log(coordinates)
	    		//const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[0]}, ${coordinates[1]}&key=AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4`
	    		const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates[0]}&lon=${coordinates[1]}`
		    	fetch(url)
			    .then((response) => response.json())
			    .then((result) => {
			        if(document.getElementById('vehicle' + vehicle.vehicleId)) {
			        	document.getElementById('vehicle' + vehicle.vehicleId).innerText = result['display_name']
			        }
			    })
			    .catch((error) => console.error(error))
		    } else {
		    	//document.getElementById('vehicle' + vehicle.vehicleId).innerHTML = '<span class="colorred">Байршил тодорхойгүй</span>'
		    }
	    })
	}, [])
	const mouseEnter = (vehicleId) => {
		if(vehicleId !== vehicle) {
			setVehicle(vehicleId)
			console.log('mousentered' + vehicleId)
		}
	}
	const mouseOut = () => {
		console.log('unreg')
		setVehicle(null)
	}
	return (
		<div id="monitoringVehiclesList">
			{
				props.data.map(vehicle => {
					return (
						<div className="borderbottom1pxsolide3e4e6 displayflex flexalignitemscenter rowVehicle" style={{padding: '.5rem'}} style={{width: '1000px'}}>
							<div className="displayflex flexjustifycontentcenter flexalignitemscenter width40px height40px">
								<input type="checkbox" checked />
							</div>
							<div className="height40px displayflex width40px flexalignitemscenter">
								<img src="/img/profile2.svg" alt="" style={{opacity: '.3', margin: '0 .25rem 0 0'}} />
							</div>
							<div className="height40px displayflex width180px flexalignitemscenter" onMouseEnter={() => mouseEnter(vehicle.vehicleId)} onMouseLeave={() => mouseOut(vehicle.vehicleId)}>
								<div>
									<p className="vehicleNumber">{vehicle.name}</p>
									<p style={{fontSize: '10px'}} className="oneline width180px" id={`vehicle${vehicle.vehicleId}`}></p>
								</div>
							</div>
							<div className="height40px width40px displayflex flexjustifycontentcenter flexalignitemscenter displaynone">
								<span className={vehicle.allCoordinates.length > 0 ? 'deviceStatus' : 'deviceStatus offline'}></span>
							</div>
							<div className="height40px width40px displayflex flexjustifycontentcenter flexalignitemscenter">
								<img style={{width: '20px'}} src={'/img/' + (vehicle.allCoordinates.length > 0 ? 'running.png' : 'stoppedred.png')} />
							</div>
							<div className="height40px displayflex width80px flexalignitemscenter">
								<img src="/img/profile2.svg" alt="" style={{opacity: '.3', margin: '0 .25rem 0 0'}} />
								<p>Batbold</p>
							</div>
							<div className="height40px displayflex width100px flexjustifycontentcenter flexalignitemscenter">
								<div>
									<p>2024-12-06</p>
									<p>15:06</p>
								</div>
							</div>
						</div>
					)
				})
			}
			{vehicle ? <Detail vehicleId={vehicle} /> : null}
		</div>
	)
}

export default MonitoringVehicles