const ProfileMenu = (props) => {
	const Signout = () => {
		localStorage.removeItem('token')
		window.location.href = '/'
	}
	return (
		<div id="profileMenu">
			<div id="profileInfo">
				<div id="profImage">
					<img src={'/image/profile/' + props.data['ProfileImage']} alt="" className="profileImage" />
				</div>
				<div id="profName">
					<p>gps@itsystem.mn</p>
				</div>
			</div>
			<div className="signout" style={{display: 'flex', alignItems: 'center', margin: '.5rem 0 0 0', padding: '.5rem 0 0 0', cursor: 'pointer'}} onClick={() => Signout()}>
				<img src="/img/logoutred.svg" alt="" style={{margin: '0 .5rem 0 0'}} /><span>Гарах</span>
			</div>
		</div>
	)
}

export default ProfileMenu