import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript, Polygon } from '@react-google-maps/api';

const libraries = ['drawing', 'geometry'];
const mapContainerStyle = {
  width: '100vw',
  height: '80vh', // Adjusted to leave space for the button
};

// Sample coordinates for the initial polygon
const initialPolygon = [
  { lat: -3.745, lng: -38.523 },
  { lat: -3.749040474224358, lng: -38.51784164810181 },
  { lat: -3.752249053650088, lng: -38.5200689907074 },
  { lat: -3.748, lng: -38.526 },
];

const calculateCentroid = (polygon, map = null) => {
  let totalLat = 0;
  let totalLng = 0;

  polygon.forEach(point => {
    totalLat += point.lat;
    totalLng += point.lng;
  });

  const centroid = {
    lat: totalLat / polygon.length,
    lng: totalLng / polygon.length,
  };

  // Fit the map to the polygon bounds
  if (map) {
    const bounds = new window.google.maps.LatLngBounds();
    polygon.forEach(point => bounds.extend(point));
    map.fitBounds(bounds, {
      left: 50,
      right: 50,
      top: 50,
      bottom: 50,
    });
  }

  return centroid;
};

const center = calculateCentroid(initialPolygon)


/*
0
: 
{lat: -3.745, lng: -38.523}
1
: 
{lat: -3.749040474224358, lng: -38.51784164810181}
2
: 
{lat: -3.752249053650088, lng: -38.5200689907074}
3
: 
{lat: -3.7526741093982157, lng: -38.52341213226319}
4
: 
{lat: -3.748, lng: -38.526}
*/

const GeoRead = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4',
    libraries,
  });

  const [polygon, setPolygon] = useState(initialPolygon);
  const mapRef = useRef();
  const polygonRef = useRef();

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;

    // Create the initial polygon
    const googlePolygon = new window.google.maps.Polygon({
      paths: initialPolygon,
      editable: true,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    });
    googlePolygon.setMap(map);
    polygonRef.current = googlePolygon;

    // Add event listeners for polygon editing
    window.google.maps.event.addListener(googlePolygon.getPath(), 'set_at', () => updatePolygonPath(googlePolygon));
    window.google.maps.event.addListener(googlePolygon.getPath(), 'insert_at', () => updatePolygonPath(googlePolygon));
  }, []);

  const updatePolygonPath = (googlePolygon) => {
    const path = googlePolygon.getPath().getArray().map(point => ({ lat: point.lat(), lng: point.lng() }));
    setPolygon(path);
  };

  const logPolygon = () => {
    if (polygon) {
      console.log(polygon);
    } else {
      console.log('No polygon drawn');
    }
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  console.log(center)

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={18}
        center={center}
        onLoad={onMapLoad}
      >
        {polygon && (
          <Polygon
            paths={polygon}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
          />
        )}
      </GoogleMap>
      <button onClick={logPolygon} style={{ marginTop: '10px', display: 'block', margin: '0 auto' }}>
        Log Polygon
      </button>
    </div>
  );
};

export default GeoRead;