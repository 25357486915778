import {APIProvider, Map, AdvancedMarker, Marker, Pin, InfoWindow} from '@vis.gl/react-google-maps'
import {Polyline} from './Polyline'
import {useState, useEffect, useCallback, useRef} from 'react'
import MonitoringVehicles from './MonitoringVehicles'

const Monitoring = () => {
    const [data, setData] = useState(null)
    const fetchData = () => {
        console.log('fetching data')
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/vehicles/get?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    function randomNumber(min, max) {
        return Math.random() * (max - min) + min;
    }
    useEffect(() => {
        //const interval = setInterval(() => {
            fetchData();
        //}, 2000)
        //return () => clearInterval(interval)
    }, [])
        const onReady = useCallback((mapInstance) => {
            alert('onReady')
    // Ensure google is available via the window object
    if (window.google) {
      mapInstance.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
    }
  }, []);
          const mapRef = useRef();

  // UseEffect hook to set the map type to satellite after the map is rendered
  useEffect(() => {
    if (mapRef.current && window.google) {
      const mapInstance = mapRef.current.getMap();  // Access the underlying Google Map instance
      mapInstance.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);  // Set to satellite view
    }
  }, []);
    return data ? (
        <>
            <MonitoringVehicles data={data} />
            <div id="monitoringMap">
                <APIProvider apiKey={"AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4"} mapType="satellite">
                <Map mapTypeId='hybrid' mapType="satellite" style={{width: '100vw', height: '100vh'}} defaultCenter={{lat: 42.476387, lng: 107.569853}} mapId={"e7dd2ce7fbfb92c2"} defaultZoom={10} gestureHandling={'greedy'} disableDefaultUI={true} >               
                    {
                        data.map(vehicle => {
                            return vehicle.allCoordinates.length > 0 ? (
                                <AdvancedMarker position={{lat: vehicle.allCoordinates[vehicle.allCoordinates.length - 1].coordinates[0], lng: vehicle.allCoordinates[vehicle.allCoordinates.length - 1].coordinates[1]}}>
                                <div style={{textAlign: 'center'}}>
                                    <img src="/img/car.svg" style={{width: '40px'}} style={{transform: 'rotate(' + randomNumber(1, 360) + 'deg)'}} />
                                    <p style={{textShadow: '-2px 0px 6px rgba(255,255,255,0.6);', fontWeight: 'bold', color: 'red', fontSize: '1rem'}} className="carOutline">{vehicle.name.substr(0, 4)}</p>
                                </div>
                                </AdvancedMarker>
                            ) : null
                        })
                    }
                </Map>
                </APIProvider>
            </div>
        </>
    ) : <div>Түр хүлээнэ үү...</div>
}

export default Monitoring
/*
                    <Polyline path={[
                    { lat: 43.531642, lng: 105.572903 },
                    { lat: 43.421820, lng: 105.725841 },
                    { lat: 43.255421, lng: 106.008094 },
                    { lat: 43.073737, lng: 106.523647 },
                    { lat: 42.608174, lng: 107.176381 },
                    ]} /> */