import {useState, useEffect} from 'react'
import Nav from './Nav'

const Navigation = (props) => {
	const [module, setModule] = useState(null)
	useEffect(() => {

	}, [])
	return (
		<div id="navigation">
			<div id="erplogo" className="margintop1rem marginbottom1rem">
				<span>
					<img src="itsys-logo.png" alt="" />
				</span>
				<span style={{fontWeight: 'bold', fontSize: '1.75rem', color: '#4798e2', marginLeft: '.25rem'}}>
					ERP
				</span>
			</div>
			<div id="menu">
				{
					props.data.Permissions.AllowedModulesList.map(name => {
						return <Nav setModule={setModule} module={module} data={name} tab={props.tab} activeModule={props.activeModule} setActiveModule={props.setActiveModule} loadSubModule={props.loadSubModule} key={name} />
					})
				}
			</div>
			<div id="copyright">
				© 2024 ITSYSTEM LLC
			</div>
		</div>
	)
}

export default Navigation

/*
	{
  
  "modules": [
    {
      "moduleName": "GeneralLedger",
      "allowedModules": ["G"]
      "divisions": [
        {
          "name": "Төв",
          "permissions": {
            "journal": {
              "entry": {
                "create": {
                  "allowedEntryCategories": ["_123", "_456"]
                }
              }
            },
            "report": {
              "read: true"
            }
          }
        },
        {
          "name": "Салбар 1",
        },
      ]
    },
    {}
  ]
}
*/

/*
			<div id="menu">
				<ul>
					<li><img src="book.svg" alt="" />Санхүү</li>
					<li className="sub">Эхний үлдэгдэл</li>
					<li className="sub">Тайлант үе</li>
					<li className="sub">Данс код</li>
					<li className="sub">Гүйлгээ жагсаалт</li>
					<li className="sub">Журнал бичилт</li>
					<li className="sub" className="sub">Журнал загвар</li>
					<li className="sub">Ханш тэгшитгэл</li>
					<li className="sub">Шалгах баланс</li>
					<li className="sub">Санхүү тодруулга</li>
				</ul>
			</div>
			<div><img src="chart.svg" alt="" />Өглөг, авлага</div>
			<div><img src="settings.svg" alt="" />Мөнгөн хөрөнгө</div>
			<div><img src="chart.svg" alt="" />Үндсэн хөрөнгө</div>
			<div><img src="settings.svg" alt="" />Бараа материал</div>
			<div><img src="chart.svg" alt="" />Борлуулалт</div>
			<div><img src="settings.svg" alt="" />Агуулах</div>
			<div><img src="chart.svg" alt="" />Үйлдвэрлэл</div>
			<div><img src="settings.svg" alt="" />Тайлан</div>
			<div><img src="chart.svg" alt="" />Тохиргоо</div> */