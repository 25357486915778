import {APIProvider, Map} from '@vis.gl/react-google-maps'
import Polygon from './Polygon'
import React, { useState, useEffect, useRef } from 'react'

const Geofences = () => {
    const polygonPoints = [[43.531642, 105.572903], [43.6, 105.6], [43.8, 105.8], [43.531642, 105.572903]]
    return (
        <div className="rightPanel">
            <APIProvider apiKey={"AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4"}>
                <Map style={{width: '100vw', height: '100vh'}} defaultCenter={{lat: 43.056300, lng: 106.431007}} mapId={"e7dd2ce7fbfb92c2"} defaultZoom={8} gestureHandling={'greedy'} disableDefaultUI={true} >               
                </Map>
            </APIProvider>
        </div>
    )
}

export default Geofences;

