import {useEffect} from 'react'

const Subs = (props) => {
	useEffect(() => {
		props.loadSubModule('Fleet Tracking')
	}, [])
	return (
		<div className="second margintophalfrem marginbottomquarterrem">
			<div style={{display: 'block'}} className="subs">
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Харилцагч')}>Харилцагч</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Эхний үлдэгдэл')}>Эхний үлдэгдэл</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Тайлант үе')}>Тайлант үе</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Данс код')}>Данс код</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Гүйлгээ жагсаалт')}>Гүйлгээ жагсаалт</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Журнал бичилт')}>Журнал бичилт</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Журнал загвар')}>Журнал загвар</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Ханш тэгшитгэл')}>Ханш тэгшитгэл</p>
				<p className="marginbottomhalfrem displaynone" onClick={() => props.loadSubModule('Шалгах баланс')}>Шалгах баланс</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Fleet Dashboard')}>Fleet Dashboard</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Fleet Tracking')}>Fleet tracking</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Geofence')}>Geofence</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Notifications')}>Notifications<span className="numberOfNotification">2</span></p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('FleetReport')}>Reports</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Tracks')}>Tracks</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Vehicles')}>Vehicles</p>
				<p className="marginbottomhalfrem" onClick={() => props.loadSubModule('Drivers')}>Drivers</p>
			</div>
		</div>
	)
}

export default Subs