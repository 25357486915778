import {useState, useEffect} from 'react'
import ProfileMenu from './ProfileMenu'

const Header = (props) => {
	const [profileMenu, setProfileMenu] = useState(false)
	const toggle = (event) => {
		console.log(event.target)
		if(!event.target.matches('#profile')) {
			console.log('might need close profile menu')
			//document.removeEventListener('click', toggle)
		}
	}
	const blah = () => {
		//document.addEventListener('click', toggle)
		setProfileMenu(!profileMenu)
	}
	const close = (e, tab) => {
		e.stopPropagation()
		document.getElementById('workspace').querySelector(`.submodule[data="${tab}"]`).remove()
		let tabs = JSON.parse(JSON.stringify(props.tabs))
		tabs.tabs = tabs.tabs.filter(t => t !== tab)
		props.setTabs(tabs)
	}
	return (
		<div id="header">
			<div id="breadcrumb">
				<div id="tabs">
					{props.tabs.tabs.length === 0 ? <span style={{lineHeight: '30px', padding: '0 0 0 .5rem'}}>Тавтай морил</span> : null}
					{props.tabs.tabs.map(tab => {
						return (
							<span key={tab} className={tab === props.tabs.activeTab ? "active tab" : "tab"} onClick={() => props.loadSubModule(tab)}>
								<p>{tab}</p>
								<span className="close" onClick={(e) => close(e, tab)}><span className="image"></span></span>
							</span>
						)
					})}
				</div>
			</div>
			<div id="profile">
				<div className="profileNotification" style={{display: 'none'}}>
					<div>
						<img src="notification.svg" alt="" className="notificationIcon" />
					</div>
					<span className="profileNotificationStatus"></span>
				</div>
				<div className="profileMenu" onClick={() => blah(true)}>
					<div>
						<img src={'/image/profile/' + props.data['ProfileImage']} alt="" className="profileImage" />
					</div>
					<span className="profileStatus"></span>
				</div>
			</div>
			{profileMenu && <ProfileMenu data={props.data} />}
		</div>
	)
}

export default Header