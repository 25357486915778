const Entry = (props) => {
	const data = props.data
	let returnEntry = true
	if(Object.entries(props.filters).length > 0) {
		for(let f in props.filters) {
			if(props.filters[f].indexOf(data[f]) === -1) {
				returnEntry = false
			}
		}
	}
	return returnEntry ? (
		<div className="row data">
			<div className="width30px displayflex flexalignitemscenter flexjustifycontentcenter">
				<input type="checkbox" className="check" />
			</div>
			<div className="width120px"><span>{data.entityCode}</span></div>
			<div className="width120px"><span>{data.entityName}</span></div>
			<div className="width120px"><span>{data.entityRegistrationNumber}</span></div>
			<div className="width120px"><span>0</span></div>
			<div className="width120px"><span>0</span></div>
		</div>
	) : null
}

export default Entry