import {useState, useEffect} from 'react'

const Geo = () => {
    const [data, setData] = useState(null)
    const fetchData = () => {
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/geofences/get?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	console.log(result)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData()
    }, [])
    return data ? (
        <div>
            <h1 className="fontweightbold fontsize1rem marginbottom1rem">Geofence доторхи машинууд</h1>
            {
                data.map(geo => {
                    return <p  className="paddinghalfrem" style={{borderBottom: '1px solid #eee'}}>{geo.name}</p>
                })
            }
        </div>
    ) : <div>Мэдээлэл татаж байна...</div>
}

export default Geo