import {useState, useEffect} from 'react'
import Entry from './Notifications/Entry'

const Notifications = () => {
	const blah = () => {
		console.log('blah()')
	}
	const [data, setData] = useState(null)
	const fetchData = () => {
        console.log('fetching data')
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/notifications/get`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	console.log(result)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData();
    }, [])
    const entries = data ? data.map(entry => {
    	return <Entry data={entry} />
    }) : []
	return data ? (
		<>
			<div id="container-button">
				<span className="button refresh">
					<img src="/img/refresh.svg" alt="" />
				</span>
				<span className="button">
					<img src="/img/delete.svg" alt="" />
					<span className="button-title">Устгах</span>
				</span>
			</div>
			<div id="container-grid">
				<div id="grid">
					<div id="head">
						<div className="row borderbottomnone">
							<div className="width30px displayflex flexalignitemscenter flexjustifycontentcenter"><input type="checkbox" /></div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Огноо</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityCode', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Утга</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityName', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Машин</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Жолооч</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span></span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
						</div>
					</div>
					<div id="data">
						{entries}
					</div>
					<div id="totals">
						<div className="row borderbottomnone">
							<div className="width30px"></div>
							<div className="width120px"></div>
							<div className="width120px"></div>
							<div className="width120px"></div>
						</div>
					</div>
				</div>
			</div>
		</>
	) : <div>Түр хүлээнэ үү...</div>
}

export default Notifications