import Subs from './Subs'

const Nav = (props) => {
	const foo = (mod) => {
		props.setActiveModule(mod)
	}
	return (
		<div className="margintop1rem">
			<div className={'first' + (props.data === props.activeModule ? ' active' : '')} onClick={() => props.tab(props.data)}>
				<div className="menuIcon">
					<img src="book.svg" alt="" />
				</div>
				<div className="moduleTitle">
					{props.data}
				</div>
			</div>
			{props.data === props.activeModule ? <Subs props={props} loadSubModule={props.loadSubModule} /> : null}
		</div>
	)
}

export default Nav