import {useState} from 'react'

const Signin = (props) => {
	const [signing, setSigning] = useState(false)
	const [errorMessageEmailAddress, setErrorMessageEmailAddress] = useState('')
	const [errorMessagePassword, setErrorMessagePassword] = useState('')
	const [errorSignin, setErrorSignin] = useState('')
	const sign = () => {
		const signEmail = document.getElementById('signEmail')
		const signPassword = document.getElementById('signPassword')
		if(signEmail.value === 'gps@itsystem.mn' && signPassword.value === 'gps') {
			window.localStorage.setItem('token', 'gps')
			window.location.href = '/'
		} else {
			setSigning(true)
			setErrorSignin('Нэвтрэх мэдээлэл буруу байна! Оруулсан мэдээллээ шалгаад дахин оролдоно уу.')
			setErrorMessageEmailAddress('Имэйл хаягаа шалгана уу!')
			setErrorMessagePassword('Нууц үгээ шалгана уу!')
			const myHeaders = new Headers()
			myHeaders.append("Content-Type", "application/json")
			const raw = JSON.stringify({
				"email": "blah"
			})
			const requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
				redirect: "follow"
			}
			fetch("https://api.itsystem.mn/signin", requestOptions)
			.then((response) => response.json())
			.then((result) => console.log(result))
			.catch((error) => console.error(error))
			setSigning(false)
		}
	}
	const pressEnter = (e) => {
		if(e.key === 'Enter') {
			sign()
		}
	}
	return signing ? (<div>Түр хүлээнэ үү...</div>) : (
		<>
			<h1 className="marginbottom1rem">Тавтай морил!</h1>
			<input type="text" id="signEmail" placeholder="Имэйл хаяг" defaultValue={props.email} onKeyUp={(e) => props.setEmail(e.target.value)} />
			<span className="error margintopquarterrem marginbottomquarterrem">{errorMessageEmailAddress}</span>
			<input type="password" id="signPassword" placeholder="Нууц үг" onKeyPress={(e) => pressEnter(e)} />
			<span className="error margintopquarterrem marginbottomquarterrem">{errorMessagePassword}</span>
			<button onClick={() => sign()}>Нэвтрэх</button>
			<span className="error margintopquarterrem marginbottomquarterrem">{errorSignin}</span>
			<p onClick={() => props.setRecovery(true)}>Нууц үгээ мартсан</p>
		</>
	)
}

export default Signin