import {useState, useEffect} from 'react'
import Entry from './Drivers/Entry'
import DriverDetail from './DriverDetail'
import NewEmployee from './NewEmployee'

const Drivers = () => {
	const [data, setData] = useState(null)
	const [appData, setAppData] = useState(null)
	const [driver, setDriver] = useState(null)
	const [newEmployee, setNewEmployee] = useState(null)
	const fetchData = () => {
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/drivers/list?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    const fetchAppData = () => {
    	console.log('fetching app data')
    	const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/drivers/appData?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	setAppData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData()
        fetchAppData()
    }, [])
	const blah = () => {
		console.log('blah()')
	}
	const entries = data ? data.map(entry => {
		return <Entry data={entry} setDriver={setDriver} />
	}) : []
	const newEmployeeFN = () => {
		console.log('newEmployeeFN')
		setNewEmployee(true)
	}
	return data && setAppData ? (
		<>
			<div id="container-button">
				<span className="button refresh">
					<img src="/img/refresh.svg" alt="" />
				</span>
				<span className="button new" onClick={() => newEmployeeFN()}>
					<img src="/img/new_white2.svg" alt="" />
					<span className="button-title">Шинэ ажилтан бүртгэх</span>
				</span>
				<span className="button">
					<img src="/img/delete.svg" alt="" />
					<span className="button-title">Устгах</span>
				</span>
			</div>
			<div id="container-grid">
				<div id="grid">
					<div id="head">
						<div className="row borderbottomnone">
							<div className="width30px displayflex flexalignitemscenter flexjustifycontentcenter"><input type="checkbox" /></div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Овог</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityCode', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Нэр</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityName', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Ажилд орсон он</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Төрсөн он</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span></span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
						</div>
					</div>
					<div id="data">
						{entries}
					</div>
					<div id="totals">
						<div className="row borderbottomnone">
							<div className="width30px"></div>
							<div className="width120px"></div>
							<div className="width120px"></div>
							<div className="width120px"></div>
						</div>
					</div>
				</div>
			</div>
			{driver ? <DriverDetail fetchData={fetchData} data={driver} setDriver={setDriver} appData={appData} /> : null}
			{newEmployee ? <NewEmployee setNewEmployee={setNewEmployee} appData={appData} fetchData={fetchData} /> : null}
		</>
	) : <div>Түр хүлээнэ үү...</div>
}

export default Drivers