import React from 'react'
import ReactDOM from 'react-dom/client'
import {useState, useEffect, memo} from 'react'
import Navigation from './Navigation'
import Header from './Header'
import Entities from './Entities/Index'
import Accounts from './Accounts/Index'
import FleetTracking from './FleetTracking/Index'
import Geo from './FleetTracking/Geofences/Index'
import StartingBalances from './StartingBalances/Index'
import Dashboard from './FleetTracking/Dashboard'
import Tracks from './FleetTracking/Tracks'
import FleetReport from './FleetTracking/Report/Index'
import Noficiations from './FleetTracking/Notifications'
import Vehicles from './FleetTracking/Vehicles'
import Drivers from './FleetTracking/Drivers'

const Workspace = () => {
	const [data, setData] = useState(null)
	const [tabs,setTabs] = useState({tabs: [], activeTab: null})
	const [zindex, setZindex] = useState(1)
	const [activeSubModule, setActiveSubModule] = useState(null)
	const [activeModule, setActiveModule] = useState(null)
	const fetchData = () => {
		/*const myHeaders = new Headers()
		myHeaders.append("Content-Type", "application/json")
		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			mode: 'no-cors'
		}
		fetch(`https://api.itsystem.mn/appData?token=gps`, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			console.log(result)
			setData(result.data)
		})
		.catch((error) => console.error(error))*/
		setData({"sysData":{"customerData":[{"_id":"a1b2c3","Code":"C1","Name":"Microsoft Inc","Register":"MSFT","VatPayerEntity":true,"Address":"USA"}],"moduleStructure":{"Санхүү":["Харилцагч","Эхний үлдэгдэл","Тайлант үе","Данс код","Гүйлгээ жагсаалт","Журнал бичилт","Журнал загвар","Ханш тэгшитгэл","Шалгах баланс","Monitoring"]}},"userData":{"Name":{"FirstName":"John","LastName":"Doe"},"ProfileImage":"johndoe.jpg","LoginEmail":"johndoe@gmail.com","Permissions":{"AllowedModulesList":["Fleet Management"],"Modules":{"Санхүү":{"allowedSubModulesList":["Харилцагч","Эхний үлдэгдэл","Тайлант үе","Данс код","Гүйлгээ жагсаалт","Журнал бичилт","Журнал загвар","Ханш тэгшитгэл","Шалгах баланс","Monitoring"]},"Fleet Management":{"allowedSubModulesList":["Map"]}}}}})
	}
	useEffect(() => {
		fetchData()
	}, [])
	const moduleTree = {
		'Харилцагч': <Entities />,
		'Эхний үлдэгдэл': <Entities />,
		'Тайлант үе': <Entities />,
		'Данс код': <Accounts />,
		'Гүйлгээ жагсаалт': <Entities />,
		'Журнал бичилт': <Entities />,
		'Журнал загвар': <Entities />,
		'Ханш тэгшитгэл': <Entities />,
		'Шалгах баланс': <Entities />,
		'Fleet Tracking': <FleetTracking />,
		'Geofence': <Geo />,
		'Fleet Dashboard': <Dashboard />,
		'Tracks': <Tracks />,
		'FleetReport': <FleetReport />,
		'Notifications': <Noficiations />,
		'Vehicles': <Vehicles />,
		'Drivers': <Drivers />
	}
	const tab = (moduleName) => {
		setActiveModule(moduleName)
	}
	const loadSubModule = (subModule) => {
		setZindex(zindex + 1)
		const query = document.getElementById('workspace').querySelector(`.submodule[data="${subModule}"]`)
		if(query) {
			query.style.zIndex = zindex
			setTabs({
				tabs: [...tabs.tabs],
				activeTab: subModule
			})
		} else {
			setTabs({
				tabs: [...tabs.tabs, subModule],
				activeTab: subModule
			})
			document.getElementById('workspace').insertAdjacentHTML('beforeEnd', `<div data-foo="bar" data="${subModule}" class="submodule" style="z-index: ${zindex}"></div>`)
			const root = ReactDOM.createRoot(document.getElementById('workspace').querySelector(`.submodule[data="${subModule}"]`))
			root.render(
				<React.StrictMode>
					{moduleTree[subModule]}
				</React.StrictMode>
			)
		}
	}
    return data ? (
        <div className="App">
            <Navigation data={data['userData']} tab={tab} activeModule={activeModule} setActiveModule={setActiveModule} setActiveSubModule={setActiveSubModule} loadSubModule={loadSubModule} />
            <Header setTabs={setTabs} loadSubModule={loadSubModule} tabs={tabs} data={data['userData']} activeModule={activeModule} activeSubModule={activeSubModule} />
            <div id="workspace"><div className="padding1rem" className="submodule"></div></div>
        </div>
    ) : <div className="container-loader"><div className="loader"></div></div>
}

export default Workspace

/*
					<span className="tab active">
						<span>Харилцагч</span>
						<span className="close"><img src="/img/closewhite.svg" alt="" /></span>
					</span>
					<span className="tab">
						<span>Харилцагч</span>
						<span className="close"><img src="/img/close.svg" alt="" /></span>
					</span>
*/