import {useState, useEffect} from 'react'

const Notifications = () => {
	const [data, setData] = useState(null)
	const fetchData = () => {
        console.log('fetching data')
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/notifications/get`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	console.log(result)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData();
    }, [])
	return data ? (
		<div>
			<h1 className="fontweightbold fontsize1rem marginbottom1rem">Notifications</h1>
			{
				data.map(noti => {
					return <div className="displayflex flexalignitemscenter widthfull"><p style={{borderBottom: '1px solid #eee', padding: '.5rem 0 .5rem 0'}}><img src="/img/car.svg" style={{width: '20px', marginRight: '.5rem'}}alt="" />{noti.date} {noti.message}</p></div>
				})
			}
		</div>
	) : <div>Мэдээлэл татаж байна...</div>
}

export default Notifications