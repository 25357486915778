const Entry = (props) => {
	const data = props.data
	return (
		<div className="row data">
			<div className="width30px displayflex flexalignitemscenter flexjustifycontentcenter">
				<input type="checkbox" className="check" />
			</div>
			<div className="width120px"><span>{data.date}</span></div>
			<div className="width120px"><span>{data.message}</span></div>
			<div className="width120px"><span></span></div>
			<div className="width120px"><span>0</span></div>
			<div className="width120px"><span>0</span></div>
		</div>
	)
}

export default Entry