import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Chart.js options
export const options = {
  responsive: true,
  interaction: {
    mode: 'index', // The 'as const' type assertion is removed because JavaScript is dynamically typed
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

// Labels for the chart
const labels = ['June', 'July', 'August', 'September', 'October', 'November', 'December'];

// Helper function to generate random numbers for datasets
function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

// Chart data
export const data = {
  labels,
  datasets: [
    {
      label: '0001УБД',
      data: labels.map(() => randomNumber(1, 10000)),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
    {
      label: '1000УДБ',
      data: labels.map(() => randomNumber(1, 10000)),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      yAxisID: 'y1',
    },
  ],
};

// Mileage component that renders the chart
const Mileage = () => {
  return <Line options={options} data={data} />;
};

// Export the Mileage component as default
export default Mileage;
