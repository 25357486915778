import {useState, useEffect} from 'react'
import TracksVehicle from './TracksVehicle'
import {APIProvider, Map} from '@vis.gl/react-google-maps'

const Tracks = (props) => {
	const [data, setData] = useState(null)
	const [trackData, setTrackData] = useState(null)
	const [dataInterval, setDataInterval] = useState(null)
	const [vehicle, setVehicle] = useState(null)
    const fetchData = () => {
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        }
        fetch(`https://api.itsystem.mn/monitoring/vehicles/list?token=${localStorage.getItem('token')}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
        	console.log(result)
            setData(() => result)
        })
        .catch((error) => console.error(error))
    }
    useEffect(() => {
        fetchData()
    }, [])
    const foo = (i) => {
    	setDataInterval(i)
    }
    const validateInputs = (inputs) => {
    	let flag = true
    	inputs.map(input => {
    		let inp = document.getElementById(input)
    		if(inp.value === '') {
    			let borderColor = inp.style.borderColor
    			inp.style.borderColor = 'red'
    			setTimeout(() => {
    				inp.style.borderColor = borderColor
    			}, 2000)
    			flag = false
    		}
    	})
    	return flag
    }
    const showTracks = () => {
    	console.log('fnShowTracks')
    	let inputs = ['tracksVehicle', 'tracksDataInterval']
    	if(dataInterval === 'interval') {
    		inputs = inputs.concat(['tracksStartDate', 'tracksEndDate'])
    	}
    	if(validateInputs(inputs)) {
    		console.log(vehicle)
    		console.log(dataInterval)
    		setTrackData(true)
    	}
    }
	return data ? (
		<>
			<div className="leftPanel">
				<div className="padding1rem">
					<select className="bigselect" id="tracksVehicle" onChange={e => setVehicle(e.target.value)}>
						<option value="">Машин сонгоно уу...</option>
						{
							data.map(vehicle => {
								return <option value={vehicle.vehicleId}>{vehicle.name}</option>
							})
						}
					</select>
					<select className="bigselect margintophalfrem" defaultValue={dataInterval} onChange={(e) => foo(e.target.value)} id="tracksDataInterval">
						<option value="" style={{color: '#ddd'}}>Хугацаа сонгоно уу...</option>
						<option value="today">Өнөөдөр</option>
						<option value="yesterday">Өчигдөр</option>
						<option value="thisweek">Энэ долоо хоног</option>
						<option value="thismonth">Энэ сар</option>
						<option value="interval">Огноо сонгох</option>
					</select>
					{dataInterval === 'interval' ? (
						<>
							<div className="margintophalfrem">
								Эхлэх огноо: <input type="date" className="floatright" id="tracksStartDate" />
							</div>
							<div className="margintophalfrem">
								Дуусах огноо: <input type="date" className="floatright" id="tracksEndDate" />
							</div>
						</>
					) : null}
					<button className="bigbutton margintophalfrem" onClick={() => showTracks()}>Шүүж харах</button>
				</div>
			</div>
			<div className="rightPanel">
				{
					trackData ? <TracksVehicle vehicleId={vehicle} dataInterval={dataInterval} /> : (
						<APIProvider apiKey={"AIzaSyCbB5PwUFq-cLlfsGRjiuirbAYxQsV5AH4"}>
	                		<Map style={{width: '100vw', height: '100vh'}} defaultCenter={{lat: 43.056300, lng: 106.431007}} mapId={"e7dd2ce7fbfb92c2"} defaultZoom={8} gestureHandling={'greedy'} disableDefaultUI={true} >               
	                		</Map>
	                	</APIProvider>
					)
				}
			</div>
		</>
	) : <div>Түр хүлээнэ үү...</div>
}

export default Tracks

//monitoring/vehicles/list