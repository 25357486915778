import {useState, useEffect, memo} from 'react'
import Entry from './Entry'
import NewEntity from './NewEntity'
import Filter from './Filter'

const List = (props) => {
	const [newEntityForm, setNewEntityForm] = useState(false)
	const [filter, setFilter] = useState(null)
	const [filterPosition, setFilterPosition] = useState(null)
	const [filteringField, setFilteringField] = useState(null)
	const [filters, setFilters] = useState({})
	const checkAll = (e) => {
		const checks = document.getElementById('data').querySelectorAll('.check')
		for(let i = 0; i < checks.length; i++) {
			checks[i].checked = e.target.checked
		}
	}
	const grid = props.data.map(data => {
		return <Entry data={data} key={data._id} filters={filters} />
	})
	const filterData = ( e) => {
		setFilterPosition({x: e.clientX, y: e.clientY})
		setFilter(true)
	}
	const toggle = (event) => {
		//if(event.target) {
			try {
				let elem = event.target
				let cleanup = true
				for(let n in elem) {
					if(elem && elem.getAttribute('id') === 'filter') {
						cleanup = false
					}
					elem = elem.parentNode
					if(elem && elem.nodeName === 'BODY')
						break
				}
				if(cleanup) {
					setFilter(false)
					document.removeEventListener('click', toggle)
				}
			} catch(exception) {

			}
		//}
	}
	const blah = (field, e) => {
		setTimeout(() => {
			document.addEventListener('click', toggle)
			setFilteringField(field)
			filterData(e)
		}, 100)
	}
	return (
		<>
			{filter && <Filter position={filterPosition} data={props.data} filteringField={filteringField} setFilters={setFilters} setFilter={setFilter} filters={filters} />}
			<div id="container-button">
				<span className="button refresh" onClick={() => props.refresh()}>
					<img src="/img/refresh.svg" alt="" />
				</span>
				<span className="button new" onClick={() => setNewEntityForm(true)}>
					<img src="/img/new_white2.svg" alt="" />
					<span className="button-title">Шинэ</span>
				</span>
				<span className="button">
					<img src="/img/duplicate.svg" alt="" />
					<span className="button-title">Хуулах</span>
				</span>
				<span className="button">
					<img src="/img/print.svg" alt="" />
					<span className="button-title">Хэвлэх</span>
				</span>
				<span className="button">
					<img src="/img/delete.svg" alt="" />
					<span className="button-title">Устгах</span>
				</span>
			</div>
			<div id="container-grid">
				<div id="grid">
					<div id="head">
						<div className="row borderbottomnone">
							<div className="width30px displayflex flexalignitemscenter flexjustifycontentcenter"><input type="checkbox" onClick={(e) => checkAll(e)} /></div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>КОД</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityCode', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Нэр</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityName', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Регистер</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Авлагын үлдэгдэл</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
							<div className="width120px displayflex flexalignitemscenter">
								<span>Өглөгийн үлдэгдэл</span>
								<img src="/image/filter.svg" className="filter" onClick={(e) => blah('entityRegister', e)} />
							</div>
						</div>
					</div>
					<div id="data">
						{grid}
					</div>
					<div id="totals">
						<div className="row borderbottomnone">
							<div className="width30px"></div>
							<div className="width120px"></div>
							<div className="width120px"></div>
							<div className="width120px"></div>
						</div>
					</div>
				</div>
			</div>
			{newEntityForm && <NewEntity setNewEntityForm={setNewEntityForm} />}
		</>
	)
}

export default List