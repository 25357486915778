import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

const Motion = () => {
    ChartJS.register(ArcElement, Tooltip, Legend)
    const data = {
        labels: ['Зогссон', 'Хөдөлгүүр асаалттай зогссон', 'Явж байгаа', 'Moving with ignition on', 'LBS detected data', 'Wi-Fi detected data', 'No actual state', 'No coordinates'],
        datasets: [
        {
            label: 'Холболтын статус',
            data: [2, 1, 3, 0, 0, 0, 0, 0],
            backgroundColor: [
                'red',
                'purple',
                'orange',
                '#eee',
                '#eee',
                '#eee',
                '#eee',
                '#eee'
                ],
            borderColor: [
                'red',
                'purple',
                'orange',
                '#eee',
                '#eee',
                '#eee',
                '#eee',
                '#eee'
                ],
            borderWidth: 1,
        },
        ]
    }
    const options = {
        plugins: {
            legend: {
                position: 'right',  // Position the legend to the right
                align: 'center',    // Center the legend horizontally
                labels: {
                    padding: 20,     // Add some vertical space between labels
                    boxWidth: 12,     // Size of the box next to the label
                    boxHeight: 12,    // Size of the box next to the label
                },
            },
        },
    }
    return <Doughnut data={data} options={options} />
}

export default Motion