import DeviceHealth from './Dashboard/DeviceHealth'
import Connection from './Dashboard/Connection'
import Notifications from './Dashboard/Notifications'
import Motion from './Dashboard/Motion'
import Geo from './Dashboard/Geo'
import Mileage from './Dashboard/Mileage'

const Dashboard = () => {
	return (
		<div className="absolutefull padding1rem">
			<div className="dashboardBox">
				<h1 className="fontweightbold fontsize1rem marginbottom1rem">Төхөөрөмжийн статус</h1>
				<DeviceHealth />
			</div>
			<div className="dashboardBox">
				<h1 className="fontweightbold fontsize1rem marginbottom1rem">Аялалын статус</h1>
				<Motion />
			</div>
			<div className="dashboardBox">
				<h1 className="fontweightbold fontsize1rem marginbottom1rem">Холболтын статус</h1>
				<Connection />
			</div>
			<div className="dashboardBox">
				<Notifications />
			</div>
			<div className="dashboardBox">
				<Geo />
			</div>
			<div className="dashboardBox">
				<h1 className="fontweightbold fontsize1rem marginbottom1rem">Туулсан зам/гүйлт</h1>
				<Mileage />
			</div>
		</div>
	)
}

export default Dashboard